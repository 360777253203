import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./style.scss";
import Typography from "../../../common/components/Typography";
import CustomSearchDropdown from "./CustomSearchDropdown";
import { Button } from "react-bootstrap";
import { fetchPlayerList } from "../../../Players/service";
import { getPaginationQuery } from "../../../utils/userUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { Action } from "../../../common/constants";
import { saveQuiz, updateQuiz } from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import { EventTypeEnum, OrderEnum } from "../../../common/constants";
import Select from "react-select";

export const QuizInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { quizObj, edit } = location?.state || {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const initialValues = edit
    ? quizObj
    : {
        matchId: "",
        questions: Array.from({ length: 11 }, () => ({
          question: "",
          eventType: "",
          win_sort_order: "",
          options: Array.from({ length: 3 }, () => ({
            playerId: "",
            multiplier: 0,
          })),
        })),
      };
  const QuizSchema = Yup.object().shape({
    matchId: Yup.string().required("Match ID is required"),
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        eventType: Yup.string().required("Event Type is required"),
        win_sort_order: Yup.string().required("Win Order is required"),
        options: Yup.array().of(
          Yup.object().shape({
            playerId: Yup.string().required("Option is required"),
            multiplier: Yup.number()
              .required("Score is required")
              .min(1, "Score must be at least 1"),
          }),
        ),
      }),
    ),
  });

  /* fetch players api */
  const fetchPlayerOptions = async (inputValue: string) => {
    return await fetchPlayerList({
      query: {
        name: inputValue,
        teamIds: [],
        leagueTypeId: null,
      },
      page: getPaginationQuery(1, 50),
    });
  };

  const mapPlayerResponseToOptions = (data: any) => {
    return data.data.players?.map((player: any) => ({
      label: player.firstName + " " + player.lastName,
      value: player.id,
    }));
  };
  const publishQuiz = async (values: any, { resetForm }: any) => {
    setIsLoading(true);
    const payload = {
      matchId: values.matchId,
      questions: values.questions.map((question, index) => ({
        questionNumber: index + 1,
        question: question.question,
        eventType: question.eventType,
        win_sort_order: question.win_sort_order,
        options: question.options.map((option, optionIndex) => ({
          optionNumber: optionIndex + 1,
          playerId: option.playerId,
          multiplier: option.multiplier,
        })),
      })),
    };

    const resData: any = edit
      ? await updateQuiz(payload, {})
      : await saveQuiz(payload, {});
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Quiz has been ${edit ? "edited" : "created"} successfully`,
        variant: "success",
      }));

      setTimeout(() => {
        navigate("/quiz");
      }, 2000);
      setIsLoading(false);
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  return (
    <>
      <Button
        className="d-flex align-items-start px-0"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="heading3" className="pb-2">
        Quiz Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={QuizSchema}
        onSubmit={publishQuiz}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Typography variant="heading4" className="p-1">
              Match ID *
            </Typography>
            <div className="input-container mb-4 w-50">
              <Field
                className="quiz-question fs-6"
                name="matchId"
                placeholder="Enter Match ID"
              />
              <ErrorMessage
                name="matchId"
                component="div"
                className="error-message"
              />
            </div>
            <div className="divider-container"></div>
            <FieldArray name="questions">
              {({ insert, remove, push }) => (
                <div>
                  {values.questions.map((question, index) => (
                    <div key={index}>
                      <Typography variant="pageTitle" className="p-1">
                        Question {index + 1}
                      </Typography>
                      <div className="relative mb-2">
                        <Field
                          className="quiz-question fs-6"
                          name={`questions.${index}.question`}
                          placeholder="Enter Question"
                        />
                        <ErrorMessage
                          name={`questions.${index}.question`}
                          component="div"
                          className="absolute py-2 px-1 top-0 right-0 text-danger"
                        />
                      </div>
                      <Typography variant="heading6" className="p-1">
                        Event Type
                      </Typography>
                      <div className="input-container mb-4 w-50">
                        <Select
                          options={Object.values(EventTypeEnum).map(
                            (eventType) => ({
                              label: eventType,
                              value: eventType,
                            }),
                          )}
                          placeholder="Select Event Type"
                          className="quiz-option-input w-50"
                          name={`questions.${index}.eventType`}
                          onChange={(e) => {
                            setFieldValue(
                              `questions.${index}.eventType`,
                              e.value,
                            );
                          }}
                          value={Object.values(EventTypeEnum)
                            .map((eventType) => ({
                              label: eventType,
                              value: eventType,
                            }))
                            .find(
                              (option) =>
                                option.value ===
                                values.questions[index].eventType,
                            )}
                        />

                        <ErrorMessage
                          name={`questions.${index}.eventType`}
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <Typography variant="heading6" className="p-1">
                        Win Sort Order
                      </Typography>
                      <div className="input-container mb-4 w-50">
                        <Select
                          options={Object.values(OrderEnum).map((order) => ({
                            label: order,
                            value: order,
                          }))}
                          placeholder="Select Win Order"
                          className="quiz-option-input w-50"
                          name={`questions.${index}.win_sort_order`}
                          onChange={(e) => {
                            setFieldValue(
                              `questions.${index}.win_sort_order`,
                              e.value,
                            );
                          }}
                          value={Object.values(OrderEnum)
                            .map((order) => ({
                              label: order,
                              value: order,
                            }))
                            .find(
                              (option) =>
                                option.value ===
                                values.questions[index].win_sort_order,
                            )}
                        />

                        <ErrorMessage
                          name={`questions.${index}.win_sort_order`}
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <FieldArray name={`questions.${index}.options`}>
                        {({ insert, remove, push }) => (
                          <div className="pt-2">
                            {question.options.map((option, optIndex) => (
                              <div
                                className="quiz-options-container quiz-option"
                                key={optIndex}
                              >
                                <Typography variant="heading6">
                                  Option {optIndex + 1}
                                </Typography>
                                <div className="input-container">
                                  <CustomSearchDropdown
                                    name={`questions.${index}.options.${optIndex}.playerId`}
                                    placeholder={`Select Option ${
                                      optIndex + 1
                                    }`}
                                    classname="quiz-option-input"
                                    fetchOptions={fetchPlayerOptions}
                                    mapResponseToOptions={
                                      mapPlayerResponseToOptions
                                    }
                                  />

                                  <ErrorMessage
                                    name={`questions.${index}.options.${optIndex}.playerId`}
                                    component="div"
                                    className="error-message"
                                  />
                                </div>

                                <Typography variant="heading6">
                                  Max Score Multiplier
                                </Typography>
                                <div className="input-container">
                                  <Field
                                    className="quiz-option-score"
                                    name={`questions.${index}.options.${optIndex}.multiplier`}
                                    placeholder="Score"
                                    type="number"
                                  />
                                  <ErrorMessage
                                    name={`questions.${index}.options.${optIndex}.multiplier`}
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>
                            ))}
                            <div className="divider-container"></div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="d-flex justify-content-start w-75">
              <Button
                type="submit"
                variant="primary"
                className="w-25"
                disabled={isLoading}
              >
                {isLoading
                  ? Action.saving
                  : edit
                  ? "Update Quiz"
                  : "Create Quiz"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};
