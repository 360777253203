import React from "react";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { Match } from "../../../common/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { Button } from "react-bootstrap";
import { getAppendedDateAndTime } from "../../../utils/matchUtil";

export const PublishMatchList = ({
  publishedMatchList,
  setPubCurrentPage,
  setPubPageSize,
  pubPageSize,
  setSelectedMatch,
  pubPage,
}) => {
  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "teamId1.name",
        id: "team1-name",
        header: () => <span>{Match.published.team1name}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "teamId2.name",
        id: "team2-name",
        header: () => <span>{Match.published.team2name}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "id",
        id: "match-id",
        header: () => <span>{Match.published.matchId}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        id: "localStartDate",
        header: () => <span>{Match.published.startDate}</span>,
        cell: ({ row }) => (
          <span className="no-wrap">
            {getAppendedDateAndTime(
              row?.original?.localStartDate,
              row?.original?.localStartTime,
            )}
          </span>
        ),
        canSort: false,
      },
      {
        accessorKey: "totalCoins",
        id: "totalCoins",
        header: () => <span>{Match.published.totalCoins}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "totalTeams",
        id: "totalTeams",
        header: () => <span>{Match.published.totalTeams}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "prizeDisbursed",
        id: "prizeDisbursed",
        header: () => <span>{Match.published.prizeDisbursed}</span>,
        cell: (info) => {
          return info?.getValue() === true
            ? "Yes"
            : info?.getValue() === false
            ? "No"
            : "";
        },
        canSort: false,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Match.unPublished.action}</span>,
        cell: (info) => (
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              setSelectedMatch(info?.row?.original);
            }}
          >
            {Match.published.view}
          </Button>
        ),
        canSort: false,
      },
    ],
    [setSelectedMatch],
  );
  return (
    <TableWrapper tableClassName="mt-3">
      <ReactTable
        isLoading={publishedMatchList?.isLoading}
        data={publishedMatchList?.data?.matches || []}
        hasError={publishedMatchList?.error?.isError}
        errorMessage={publishedMatchList?.error?.errorMsg}
        columns={columns}
        configuration={{
          sorting: [],
          getRowClassNames: (row: any) => {
            return row?.featured ? "special-match-row" : "";
          },
        }}
        pageChangeHandler={setPubCurrentPage}
        pageSizeHandler={setPubPageSize}
        totalRows={publishedMatchList?.data?.totalCount} //total records
        rowsPerPage={pubPageSize} //no of records per page
        currentPageNumber={pubPage}
      />
    </TableWrapper>
  );
};
