import API from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { HttpStatus } from "../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../utils/error/errorMsg";
import { handleApiRequest } from "../utils/apiUtil";
import { API_METHOD } from "../api/api";

export const fetchQuizList = async (payload = {}, config = {}) => {
  try {
    const url: string = getBase() + "/api" + URLS.QUIZ_LIST_URL;
    const data: any = await API.get(url, payload, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const saveQuiz = async (payload = {}, config: any = {}) => {
  const url: string = getBase() + "/api" + URLS.QUIZ_LIST_URL;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const updateQuiz = async (payload = {}, config: any = {}) => {
  const url: string = getBase() + "/api" + URLS.QUIZ_LIST_URL;
  return await handleApiRequest(API_METHOD.PUT, url, payload, config);
};
