import React, { useState } from "react";
import Select from "react-select/async";
import ReactToastr from "../../../common/components/ReactToaster";
import { useFormikContext } from "formik";

interface OptionType {
  label: string;
  value: string;
}

interface CustomSearchDropdownProps {
  name: string;
  placeholder: string;
  classname?: any;
  fetchOptions: (inputValue: string) => Promise<any>;
  mapResponseToOptions: (data: any) => OptionType[];
}
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    borderColor: "#ced4da",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#007bff",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007bff"
      : state.isFocused
      ? "#e7f1ff"
      : null,
    color: state.isSelected ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#e7f1ff",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const CustomSearchDropdown: React.FC<CustomSearchDropdownProps> = ({
  name,
  placeholder,
  classname,
  fetchOptions,
  mapResponseToOptions,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const loadOptions = async (inputValue: string) => {
    try {
      const response = await fetchOptions(inputValue);
      return mapResponseToOptions(response.data);
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Something went wrong, please try again",
        variant: "danger",
      }));
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const handleChange = (option: OptionType | null) => {
    setSelectedOption(option);
    setFieldValue(name, option?.value);
  };
  return (
    <>
      <Select
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        value={values[name]} // value={selectedOption}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        styles={customStyles}
        className={classname}
        isClearable
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default CustomSearchDropdown;
