import { API_METHOD } from "../api/api";
import { getBase } from "../apiconfig";
import { URLS } from "../common/url";
import { handleApiRequest } from "../utils/apiUtil";
import { CustomStatusCode, CustomStatusCodeMessage } from "../utils/constants";
import { setLoggedInUser } from "../utils/userUtil";

export const fetchMatchList = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.MATCH_URL + "/list";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const fetchMatchConfig = async (payload = {}, config: any = {}) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/${matchId}/players`;
  return await handleApiRequest(API_METHOD.GET, url, payload, restConfig);
};

// To publish the prize and futty player
export const publishPrizeAndFuttyPlayer = async (
  payload = {},
  config: any = {},
) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/${matchId}/publish`;
  return await handleApiRequest(API_METHOD.PUT, url, payload, restConfig);
};

// To save the prize and futty player
export const savePrizeAndFuttyPlayer = async (
  payload = {},
  config: any = {},
) => {
  const { matchId, ...restConfig } = config;
  const url: string = getBase() + "/api" + URLS.MATCH_URL + `/${matchId}`;
  return await handleApiRequest(API_METHOD.PUT, url, payload, restConfig);
};

// To re-run the score calculation
export const triggerScoreCalculation = async (
  payload = {},
  config: any = {},
) => {
  const { matchId, ...restConfig } = config;
  const url: string = getBase() + "/api" + URLS.MATCH_URL + `/score/${matchId}`;
  return await handleApiRequest(API_METHOD.GET, url, payload, restConfig);
};

// To disburse the prize
export const triggerDisbursePrize = async (payload = {}, config: any = {}) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/distributePrize/${matchId}`;
  return await handleApiRequest(API_METHOD.PUT, url, payload, restConfig);
};

// Function to refresh token
export async function refreshToken() {
  try {
    // Send a request to the server to refresh the token
    const url: string = getBase() + "/api" + URLS.USER_URL + `/refreshToken`;
    const mobileNumber = localStorage.getItem("mobileNumber");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: mobileNumber,
        refreshToken: `${localStorage.getItem("refreshToken")}`,
      }),
    });

    if (!response.ok) {
      const resData = await response.json();
      // eslint-disable-next-line no-throw-literal
      throw {
        status: CustomStatusCode.sessionExpire,
        data: {
          message:
            resData?.message ||
            CustomStatusCodeMessage[CustomStatusCode.sessionExpire],
        },
      };
    }
    const data = await response.json();
    const newToken = data?.data;
    setLoggedInUser(newToken, mobileNumber);
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw error;
  }
}

// To download the leaderboard for a match
export const downloadMatchUserTeamsApi = async (config: any = {}) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() +
    "/api" +
    URLS.MATCH_USERS_TEAMS +
    `/leaderBoard/${matchId}/export`;
  return await handleApiRequest(API_METHOD.GET, url, {}, restConfig);
};

// To save the prize and futty player
export const downloadPlayerReportApi = async (
  payload = {},
  config: any = {},
) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/score/${matchId}/export`;
  return await handleApiRequest(API_METHOD.GET, url, payload, restConfig);
};

// To save the matches report for the prize disbursed
export const downloadMatchesReportApi = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.MATCH_URL + `/export`;
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const sendMatchPushNotification = async (payload = {}, config = {}) => {
  const url: string = getBase() + "/api" + URLS.MATCH_USERS_TEAMS + "/notify";
  return await handleApiRequest(API_METHOD.POST, url, payload, config);
};

export const fetchTransferPlayerList = async (config: any = {}) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/${matchId}/teamChanges`;
  return await handleApiRequest(API_METHOD.GET, url, {}, restConfig);
};

export const syncMatchData = async (config: any = {}) => {
  const { matchId, ...restConfig } = config;
  const url: string =
    getBase() + "/api" + URLS.MATCH_URL + `/${matchId}/syncMatchPlayers`;
  return await handleApiRequest(API_METHOD.PUT, url, {}, restConfig);
};

// To mark/unmark match as special
export const markUnmarkMatchAsSpecialApi = async (payload) => {
  const url: string = getBase() + "/api" + URLS.MATCH_URL + `/featured`;
  return await handleApiRequest(API_METHOD.PUT, url, payload);
};
