import React, { useState, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Card, CardBody } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { restrictAlpha } from "../../../utils/userUtil";
import { Action, Match, prizeConfigMessage } from "../../constants";
import { Delete } from "../../SVG";
import TableWrapper from "../ReactTable/TableWrapper";
import ReactTable from "../ReactTable/ReactTable";
import { showFailureToaster } from "../../../utils/apiUtil";
import "./style.scss";

const PrizeConfig = ({
  isLoading,
  isError,
  errorMsg,
  prizeList,
  setPrizeList,
  isReadOnly,
  setShowToaster,
  isDisabledDeleteRow,
  isFromFieldDisabled = false,
  isToFieldDisabled = false,
  canSetDefaultToAndFromValue = false,
}) => {
  const [newPrize, setNewPrize] = useState({ from: "", to: "", coins: "" });
  useEffect(() => {
    if (canSetDefaultToAndFromValue && !isReadOnly && prizeList?.length === 0) {
      setNewPrize({ from: "1", to: "1", coins: "" });
    } else {
      setNewPrize({ from: "", to: "", coins: "" });
    }
  }, [canSetDefaultToAndFromValue, isReadOnly, prizeList]);
  const handleDelete = useCallback(
    (deletedIndex) => {
      const filterPrizeList = prizeList?.filter((item, index) => {
        return index !== deletedIndex;
      });
      setPrizeList(filterPrizeList);
    },
    [setPrizeList, prizeList],
  );

  const handleNewPrizeConfig = (key, e) => {
    if (restrictAlpha(e.nativeEvent.data) || !e.nativeEvent.data) {
      setNewPrize((prevPrize) => ({ ...prevPrize, [key]: e.target.value }));
    }
  };
  const prizeListColumns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "from",
        id: "from",
        header: () => <span>{Match.configure.fromText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "to",
        id: "to",
        header: () => <span>{Match.configure.toText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "coins",
        id: "coins",
        header: () => <span>{Match.configure.amountText}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "Action",
        id: "action",
        header: () => <span>{Match.configure.action}</span>,
        cell: (info) => {
          return (
            <Button
              disabled={isDisabledDeleteRow}
              size="sm"
              variant="link"
              onClick={() => {
                !isDisabledDeleteRow && handleDelete(info?.row?.index);
              }}
            >
              <Delete />
            </Button>
          );
        },
        canSort: false,
      },
    ],
    [handleDelete, isDisabledDeleteRow],
  );
  return (
    <>
      <div className="prize-config-table-wrapper">
        <TableWrapper>
          <ReactTable
            isLoading={isLoading}
            data={prizeList || []}
            columns={prizeListColumns}
            configuration={{ sorting: [] }}
            hasError={isError}
            errorMessage={errorMsg}
          />
        </TableWrapper>
      </div>
      {!isLoading && !isError && (
        <Card className="add-prize-card">
          <CardBody className="add-prize-card-body">
            <div className="d-flex">
              <div className="input-div">
                <Form.Control
                  type="input"
                  id="from-input"
                  className="border-left-style"
                  placeholder={Match.configure.fromText}
                  onChange={(e) =>
                    !isFromFieldDisabled && handleNewPrizeConfig("from", e)
                  }
                  value={newPrize.from}
                  disabled={isFromFieldDisabled}
                />
              </div>
              <div className="input-div">
                <Form.Control
                  type="input"
                  id="from-input"
                  placeholder={Match.configure.toText}
                  onChange={(e) =>
                    !isToFieldDisabled && handleNewPrizeConfig("to", e)
                  }
                  value={newPrize.to}
                  disabled={isToFieldDisabled}
                />
              </div>
              <div className="input-div">
                <Form.Control
                  type="input"
                  id="from-input"
                  placeholder={Match.configure.amountText}
                  onChange={(e) => handleNewPrizeConfig("coins", e)}
                  value={newPrize.coins}
                />
              </div>
              <div className="input-div">
                <Button
                  className="add-prize-button"
                  disabled={isReadOnly}
                  onClick={() => {
                    if (!isReadOnly) {
                      const fromPrize = parseInt(newPrize.from);
                      const toPrize = parseInt(newPrize.to);
                      const coins = parseInt(newPrize.coins);
                      if (isNaN(fromPrize) || isNaN(toPrize) || isNaN(coins)) {
                        setShowToaster(
                          showFailureToaster(
                            prizeConfigMessage.prizeShouldBeNumber,
                          ),
                        );
                      } else if (fromPrize < 0 || toPrize < 0 || coins < 0) {
                        setShowToaster(
                          showFailureToaster(
                            prizeConfigMessage.prizeCantNegative,
                          ),
                        );
                      } else if (fromPrize > toPrize) {
                        setShowToaster(
                          showFailureToaster(
                            prizeConfigMessage.fromShouldLessThanTo,
                          ),
                        );
                      } else {
                        const newPrizeList = [
                          ...prizeList,
                          {
                            from: fromPrize,
                            to: toPrize,
                            coins: coins,
                          },
                        ];
                        const sortedPrizeList: any = newPrizeList.sort(
                          (a, b) => parseInt(a?.from) - parseInt(b?.from),
                        );
                        setPrizeList(sortedPrizeList);
                        setNewPrize({ from: "", to: "", coins: "" });
                      }
                    }
                  }}
                >
                  {Action.addNewPrize}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PrizeConfig;
